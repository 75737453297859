import { Carousel } from "react-bootstrap";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usersData } from '../../menu.json'
import { login } from "../../app/controllers/userSlice";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {

  const { isAuthenticated } = useSelector((state) => state.user)

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleLogin = () => {
    // Cari pengguna dengan username yang sesuai
    const user = usersData.find((user) => user.username === username);

    // Validasi sederhana
    if (user && user.password === password) {
      dispatch(login({ username: user.username, name: user.name }));
      navigate('/')

      Swal.fire({
        html: `
            <div class="main-loader">
            <div class="container-loader">
                <div class="ring"></div>
                <div class="ring"></div>
                <div class="ring"></div>
                <span class="loading text-white">Please wait . . .</span>
            </div>
        </div>
        `,
        timer: 3000,
        showConfirmButton: false,
        background: "transparent",
        backdrop: "rgba(0, 0, 0, 0.91)"
      });

    } else {
      Swal.fire({
        icon: "error",
        width: 600,
        title: "Oops...",
        text: "User & Password not found!",
      });
    }
  };

  return (
    <>
      {/* <!--start content--> */}
      <main className="authentication-content fixed-top">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-4 mx-auto">
              <div className="d-flex justify-content-center mb-4">
                <img src="/img/logo-mpc.png" alt="Logo" />
              </div>
              <div className="card shadow rounded-5 overflow-hidden ">
                <div className="card-body p-4 p-sm-5">
                  <h3 className="text-center fs-2 fw-bold card-title">Login kali </h3>
                  <p className="text-center fs-5 card-text mb-5">H2H Bank Monitoring</p>
                  <form className="form-body">
                    <div className="row g-3">
                      <div className="col-12">
                        <label
                          htmlFor="inputEmailUsername"
                          className="form-label"
                        >
                          Enter Username
                        </label>
                        <div className="ms-auto position-relative">
                          <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                            <i className="bi bi-person-fill"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control radius-30 ps-5"
                            id="inputEmailUsername"
                            value={username} onChange={(e) => setUsername(e.target.value)}
                            placeholder="Enter Username"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <label
                          htmlFor="inputChoosePassword"
                          className="form-label"
                        >
                          Enter Password
                        </label>
                        <div className="ms-auto position-relative">
                          <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                            <i className="bi bi-lock-fill"></i>
                          </div>
                          <input
                            type="password"
                            className="form-control radius-30 ps-5"
                            id="inputChoosePassword"
                            value={password} onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter Password"
                          />
                        </div>
                      </div>

                      <div className="col-12 mt-4">
                        <div className="d-grid">
                          <button onClick={handleLogin} className="btn btn-primary rounded" type="button">
                            Sign In
                          </button>
                          {isAuthenticated && <Link className="text-center mt-5" to="/">Back to Home</Link>}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Carousel className="bg-carousel">
        <Carousel.Item>
          <img src="/img/bg/bg1.png" alt="img-carousel" className="img-carousel" />
        </Carousel.Item>
        <Carousel.Item>
          <img src="/img/bg/bg2.png" alt="img-carousel" className="img-carousel" />
        </Carousel.Item>
        <Carousel.Item>
          <img src="/img/bg/bg3.png" alt="img-carousel" className="img-carousel" />
        </Carousel.Item>
        <Carousel.Item>
          <img src="/img/bg/bg4.png" alt="img-carousel" className="img-carousel" />
        </Carousel.Item>
        <Carousel.Item>
          <img src="/img/bg/bg5.png" alt="img-carousel" className="img-carousel" />
        </Carousel.Item>
        <Carousel.Item>
          <img src="/img/bg/bg6.png" alt="img-carousel" className="img-carousel" />
        </Carousel.Item>
        <Carousel.Item>
          <img src="/img/bg/bg7.png" alt="img-carousel" className="img-carousel" />
        </Carousel.Item>
        <Carousel.Item>
          <img src="/img/bg/bg8.png" alt="img-carousel" className="img-carousel" />
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default Login;
