import { DNA } from 'react-loader-spinner'
import '../assets/css/loader.css'

export default function MpcLoader() {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center align-items-center InovuaReactDataGrid__load-mask">
        <div className="col-12 text-center spin">
          <DNA visible={true} height="80" width="80" wrapperClass="dna-wrapper"/> <br />
          <span className="fs-4">Please wait...</span>
        </div>
      </div>
    </div>
  )
}
