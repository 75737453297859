import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const developmentApi = 'http://localhost:8125'
// const developmentApi = ''

export const api = createApi({
    reducerPath: 'api',
    tagTypes: ['api'],
    baseQuery: fetchBaseQuery({baseUrl: `/api`}),
    endpoints: (builder) => ({
        getAccountStatements: builder.query({
            query: ( bank ) => `${bank}/getdata/investor-account/statement?Pass=WilliamDima270388`
        }),

        getTableData: builder.query({
            query: (params) => {
                const { tablename, skip, limit, search, sort, order, filter, nidkey, nidvalue } = params;
                return {
                    url: `generic/data/getdata?tablename=${tablename}&skip=${skip}&limit=${limit}&search=${search}&sort=${sort}&order=${order}&filter=${filter}&nidkey=${nidkey}&nidvalue=${nidvalue}`,
                    method: 'GET'
                }
            },
            providesTags: ['api']
        }),

        getHeaderData: builder.query({
            query: (params) => {
                const{ tablename } = params;
                return {
                    url: `generic/data/header?tablename=${tablename}`,
                    method: 'GET'
                }
            }
        }),

        getSingleData: builder.query({
            query: (params) => {
                const { tablename, column, paramid } = params;

                return {
                    url: `generic/data/getsingle?tablename=${tablename}&column=${column}&paramid=${paramid}`,
                    method: 'GET'
                }
            }
        }),

        injectData : builder.query({
            query: (params) => {
                const { bank, paramid } = params;
                return {
                    url: `${bank}/inject/statement?Pass=WilliamDima270388&StatementID=${paramid}`,
                    method: 'GET'
                }
            },
            invalidatesTags: ['api']
        }),
    })
})

export const { 
    useGetAccountStatementsQuery, 
    useInjectDataQuery, 
    useGetTableDataQuery, 
    useGetHeaderDataQuery,
    useGetSingleDataQuery,
 } = api;