/* eslint-disable react/prop-types */
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { logout } from "../app/controllers/userSlice";
import { memo } from "react";

const Header = memo(function HeaderComponent({toggleSideBar}) {

    const dispatch = useDispatch();

    const handleLogout = () => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#success",
        cancelButtonColor: "#d33",
        confirmButtonText: "Logout?",
        background: "#fff",
        backdrop: "rgba(0, 0, 0, 0.91)"
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(logout());
        }
      });
      
    };

  return (
    <header className={`header`}>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className='col-8'>
          <img src="/img/logo-mpc.png" alt="logo s21" className='mx-1 logos21' style={{width: 150}}/>
          <button className='header-toggle' onClick={toggleSideBar}>
            <img className="svg" src="/svg/align-center-alt.svg" alt="toggle" style={{width: 20}}/>
          </button>
          <span className='nav-logo-name'>MICROPIRANTI COMPUTER</span>
        </div>
        <div className='col-4'>
          <div className="text-end">
              {/* <button className="btn mx-3"> 
                <i className="bi bi-bell fs-4"></i>
               </button> */}
              <button className="btn btn-primary btn-default btn-squared" onClick={handleLogout}>Logout</button>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
})

export default Header;