/* eslint-disable react/prop-types */
import { Fragment } from "react"
import { Modal } from "react-bootstrap"
import { useGetSingleDataQuery } from "../../app/services/api";
import { DNA } from "react-loader-spinner";

function MainModal ({ show, bank, title, closeModal, paramid }) {

  const { data: dataBO, isLoading, isFetching, isSuccess } = useGetSingleDataQuery(paramid)

  let columns = [];
  let data = [];

  if(dataBO) {
    columns = dataBO ? Object.keys(dataBO[0]).map((key) => ({
      field: key,
      header: key.charAt(0).toUpperCase() + key.slice(1),
    })) : [];

    data = dataBO[0]
  }
    
  let modalBody;
  if(isLoading) {
    modalBody = <Fragment className="container">
    <div className="row justify-content-center align-items-center">
      <div className="col-12 text-center">
        <DNA visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper"/>
      </div>
    </div>
  </Fragment>
  } else if(isFetching) {
    modalBody = <Fragment className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 text-center">
          <DNA visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper"/>
        </div>
      </div>
    </Fragment>
  } else if(isSuccess) {
    modalBody = <Fragment>
      <div className="modal-body">
          <form className="col-12">
              <div className="row">
              {columns.map((column) => (
                  <div key={column.field} className="form-group col-md-3">
                  <label htmlFor={`filter${column.field}`}>{column.header.replace(/([a-z])([A-Z])/g, '$1 $2')}</label>
                  {(
                    <input
                      type="text"
                      disabled
                      className="form-control"
                      id={`filter${column.field}`}
                      placeholder={`Enter ${column.field} filter`}
                      value={data[column.field] || '-'}
                    />
                  )}
                </div>
              ))}
              </div>
            </form>
          </div>
          <div className="modal-footer px-2">
              <button type="button" onClick={closeModal} className="btn btn-danger">
                  <i className="bi bi-bi bi-x-circle mx-1"></i> Close
              </button>
          </div>
    </Fragment>
  }

  return (
    <Modal show={show} className="modal modal-lg fade" id="staticBackdrop" data-bs-backdrop="static">
        <div className="modal-content p-2">
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">{bank} { title }</h5>
                <button type="button" onClick={closeModal} className="btn-close" aria-label="Close"></button>
            </div>
            {modalBody}
        </div>
    </Modal>
  )
}

export default MainModal