import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import 'swiper/css';
import '@inovua/reactdatagrid-community/index.css'
import 'rsuite/dist/rsuite.min.css';
import './assets/css/custom-reactdatagrid.css'
import './assets/css/custom.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/pace.min.css'
import './assets/js/pace.min.js'
import './assets/plugins/metismenu/css/metisMenu.min.css'
import './assets/css/bootstrap-extended.css'
import './assets/css/header-colors.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import 'react-toastify/dist/ReactToastify.css';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Login from './pages/auth/login.jsx';
import Dashboard from './pages/auth/dashboard.jsx';
import Deposit from './pages/deposit/deposit.jsx';
import { Provider } from 'react-redux'
import { store } from './app/store.js';
import OnlineTransfer from './pages/onlinetransfer/onlinetransfer.jsx';
import SaldoRekening from './pages/saldorekening/saldorekening.jsx';
import PrivateRoute from './pages/auth/PrivateRoute.jsx';
import NotFound from './pages/notFound.jsx';
import { ToastContainer } from 'react-toastify';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path='/login' element={<Login/>}/>

      {/* Protected route */}
      <Route element={<PrivateRoute/>}>
        <Route path='/' element={<App/>}>
          <Route path='/' element={<Dashboard/>}/>
          <Route path='deposit' element={<Deposit/>}>
            <Route path=':bankId' element={<Deposit/>}/>
          </Route>
          <Route path='instruksi' element={<OnlineTransfer/>}>
            <Route path=':bankId' element={<OnlineTransfer/>}/>
          </Route>
          <Route path='saldorekening' element={<SaldoRekening/>}>
            <Route path=':bankId' element={<SaldoRekening/>}/>
          </Route>
        </Route>
        
      </Route>

      <Route path='/*' element={<NotFound/>}/>
    </Route>
  )
)

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ToastContainer/>
    <Provider store={store}>
      <RouterProvider router={router}/>
    </Provider>
  </React.StrictMode>,
)
