/* eslint-disable react/prop-types */
import { memo } from "react";
import { Accordion, useAccordionButton } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function MenuDropdown({ children, eventKey, show, icon }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log('totally custom!'),
  );

  return (
    <a className='cursor-pointer nav-link' onClick={decoratedOnClick}>
        <div className="parent-icon">
          <i className={icon}></i>
        </div>
          <span className={`nav-link-name menu-title ${!show ? 'd-none': 'show'}`}>{children}</span>
      </a>
  );
}

const Sidebar = memo(function Sidebar({show}) {

  const { menu } = useSelector((state) => state.menu)

  return (
    <aside className={`sidebar ${show ? 'show' : null}`}>
        <nav className='nav'>
            <ul className='metismenu'>
                <li className={`${show ? 'show' : null} item-menu`}>
                  <Link to='/' className='nav-link'>
                    <div className="parent-icon">
                      <i className="bi bi-grid-fill"></i>
                    </div>
                      <span className={`nav-link-name menu-title ${!show ? 'd-none': 'show'}`}>Dashboard</span>
                  </Link>
                </li>     
                <li className={`${show ? 'show' : null} item-menu`}>
                  <ul className="me-3 menuDropdown">
                    {menu && menu.map((item) => (
                      <Accordion key={item.menunid}>
                        <MenuDropdown show={show} eventKey={item.menunid} icon={item.menuIcon}>
                          <span span className="nav-link-dropdown">{item.menuHeader}</span>
                        </MenuDropdown>
                        <Accordion.Collapse eventKey={item.menunid} className="dropdown">
                          <li className="list-dropdown">
                            {item.bank.map((itemBank) => (
                              <Link to={`/${item.menuid}/${itemBank.bankId}`} key={itemBank.bankNID} title={itemBank.bankId.toUpperCase()}>
                                <div className="parent-icon">
                                  <i className={`${itemBank.bankIcon} dropdownIcon`} ></i>
                                </div>
                                {show && <span className={`nav-link-name menu-title item-dropdown`}>{itemBank.bankId.toUpperCase()}</span>}
                            </Link>
                            ))}
                          </li>
                        </Accordion.Collapse>
                      </Accordion>
                    ))}
                  </ul>
                </li>    
            </ul>
        </nav>
    </aside>
  )
})

export default Sidebar;