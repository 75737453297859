/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useParams } from "react-router-dom";
import ReactDataGridTable from "../../components/tables/ReactDataGridTable";
import ModalDetail from '../../components/modals/ModalDetail'
import moment from "moment";

export default function OnlineTransfer() {

  let defaultValue = {
    TransactionDate: moment(Date.now()).format("YYYY-MM-DD")
  };

  const [showFilter, setShowFilter] = useState(false);
  const [paramid, setParamid] = useState('')
  const [nidKey, setNidKey] = useState('')
  const [showModal, setShowModal] = useState(false)

  const tableName = location.pathname.replace(/\//g, '');
  const { bankId } = useParams()

  const filterValue = tableName ? localStorage.getItem(tableName) : [];

  useEffect(() => {
    localStorage.setItem(tableName, JSON.stringify(defaultValue));
  }, [])

  const openModalFilter = () => {
    setShowFilter(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const closeModalFilter = () => {
    setShowFilter(false)
  }

  const getDetailData = () => {
    setShowModal(true)
    setParamid({ tablename: `${tableName}`, column: 'AutoNID', paramid: nidKey })
  }

  const selectData = (event) => {
    const nid = event.data.AutoNID;
    if(nid) {
      setNidKey(nid)
    }
  }

  return (
    <Layout title={`Monitoring Online Transfer |  ${bankId.toUpperCase()}`}>
     <div className="main-account">
        <div className="col-12 header-account">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-12">
            <nav aria-label="Page navigation example">
              <ul className="pagination pagination-lg">
                <li className="page-item" title="Filter">
                  <button onClick={openModalFilter} className="btn btn-primary page-link bi bi-search"></button>
                </li>
                {
                  filterValue ? Array(JSON.parse(filterValue)).map((value) => (
                    <li key={value} className="page-item" title="Filter"> 
                      <span className="ms-3">Filtered by:</span>
                      <p className="ms-3">
                      { Object.keys(value).length <= 0 ? (
                        <span className="fw-bold fs-5 me-2">None</span>
                      ) : Object.values(Object.entries(value)).map((item) => (
                        <span className="fw-bold" key={item}><span className="fs-5 me-2"> |</span>{item[0]}: {item[1]}</span>
                      ))}</p>
                      
                    </li>  
                  )) : 'None'
                  
                }
                
              </ul>
            </nav>
            </div>
          </div>
        </div>
        <div className="col-12">
          {paramid && <ModalDetail show={showModal} bank={bankId.toUpperCase()} closeModal={closeModal} title="Deposit Detail" paramid={paramid} />}
          <ReactDataGridTable doubleClickEvent={getDetailData} selectData={selectData} showFilter={showFilter} closeModalFilter={closeModalFilter} selected={nidKey} filterValue={filterValue}/>
        </div>
      </div>
    </Layout>
  )
}
