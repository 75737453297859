import { createSlice } from "@reduxjs/toolkit";
import menu from '../../menu.json'

export const menuSlice = createSlice({
    name: "menu",
    initialState: menu,
    reducers: {
        
    }
})

export default menuSlice.reducer