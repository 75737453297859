import { configureStore } from '@reduxjs/toolkit'
import menuReducer from './controllers/menuSlice'
import userReducer from './controllers/userSlice'
import { setupListeners } from '@reduxjs/toolkit/query'
import { api } from './services/api'

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    menu: menuReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware().concat(api.middleware)
  )
})
 
setupListeners(store.dispatch)