
export default function Footer() {
  return (
    <footer className="navbar fixed-bottom navbar-expand-sm justify-content-center">

        <div className="col-12 col-md-4">
            <div className="footer-end text-center">
                <img src="/img/logo-ojk.png" alt=""/>
                <img src="/img/logo-akses-kesei.png" alt="" />
                <img src="/img/logo-inklusi-keuangan.png" alt="" />
                <img src="/img/logo-idx.png" alt="" />
            </div>
        </div>

        <div className="col-12 col-md-6 mt-2  text-center">
            <p className="footer-end1">
                &copy; 2024. PT Micropiranti Computer Company Member of the Indonesia Stock Exchange, licensed,
                registered and supervised by the Financial Service Authority.
            </p>
        </div>

    </footer>
  )
}
