/* eslint-disable react/prop-types */
export default function Layout({ children, title }) {

  return (
    <div className="row">
        <div className="col-xl-12">
            <div className="card-header mt-3 px-5 bg-primary">
              <h3 className="banner-feature__heading color-dark fw-bold text-white">{title}</h3>
            </div>
            <div className="card-body px-25">
                {children}
            </div>
        </div>
    </div>
  )
}
