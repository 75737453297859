import { memo } from "react";
import { useSelector } from "react-redux"
import { Navigate, Outlet, useLocation } from "react-router-dom"

const PrivateRoute = memo(function PrivateRoute() {
    const location = useLocation();

    const { isAuthenticated } = useSelector((state) => state.user)
  return isAuthenticated ? (
   <Outlet/>
  ) : (
    <Navigate to="/login" replace={true} state={{from: location}}/>
  )
})

export default PrivateRoute