/* eslint-disable react/prop-types */
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { DateRangePicker } from "rsuite";
// import { DateRangePicker } from "rsuite";

function ModalFilter(props) {

  const { show, closeModalFilter, columns, applyFilters } = props;

  const tableName = location.pathname.replace(/\//g, '');

  const defaultFilter = JSON.parse(localStorage.getItem(tableName));

  const [filterValues, setFilterValues] = useState(defaultFilter);

  const handleFilterChange = (event, name) => {
    setFilterValues({ ...filterValues, [name]: event });
  };

  const clearFilter = () => {
    setFilterValues({});
  };
    
  return (
    <Modal show={show} className="modal modal-md fade" id="staticBackdrop" data-bs-backdrop="static">
      <div className="modal-content p-2">
          <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Filter Data {tableName.toUpperCase()} </h5>
              <button type="button" onClick={closeModalFilter} className="btn-close" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          <form className="col-12">
              <div className="row">
              {columns ? columns.map((column) => (
                  <div key={column.name} className="form-group col-md-6">
                  <label htmlFor={`filter${column.name}`}>{column.header.replace(/([a-z])([A-Z])/g, '$1 $2')}</label>
                  {column.name === 'StatusBO' ? (
                    <select
                      className="form-control"
                      id={`filter${column.name}`}
                      onChange={(event) => handleFilterChange(event.target.value, column.name)}
                      value={filterValues[column.name] || ''}
                    >
                      <option value="">--- Select {column.header} ---</option>
                      <option value="SUCCESS">SUCCESS</option>
                      <option value="SKIPPED">SKIPPED</option>
                    </select>
                  ) : column.name === 'TransactionCode'? (
                      <select
                      className="form-control"
                      id={`filter${column.name}`}
                      onChange={(event) => handleFilterChange(event.target.value, column.name)}
                      value={filterValues[column.name] || ''}
                    >
                      <option value="">--- Select {column.header} ---</option>
                      <option value="DEBIT">DEBIT</option>
                      <option value="CREDIT">CREDIT</option>
                    </select>
                  ) : column.name === "TransactionDate" ? (
                    <input
                      type="date"
                      className="form-control"
                      id={`filter${column.name}`}
                      placeholder={`Enter ${column.name} filter`}
                      onChange={(event) => handleFilterChange(event.target.value, column.name)}
                      value={filterValues[column.name] || ''}
                    />
                  )  : column.name === "ReceiveTime" ? (
                    // <input
                    //     type="date"
                    //     className="form-control"
                    //     id={`filter${column.name}`}
                    //     placeholder={`Enter ${column.name} filter`}
                    //     onChange={(e) => handleFilterChange(e, column.name)}
                    //     value={filterValues[column.name] || ''}
                    //   />
                      <DateRangePicker
                        format="yyyy-MM-dd"
                        size="xs"
                        className="form-control border-none"
                        id={`filter${column.name}`}
                        onChange={(event) => handleFilterChange(event, column.name)}
                        value={filterValues[column.name] || []}
                      />
                  ) : column.name === "SendTime" ? (
                    <input
                      type="date"
                      className="form-control"
                      id={`filter${column.name}`}
                      placeholder={`Enter ${column.name} filter`}
                      onChange={(event) => handleFilterChange(event.target.value, column.name)}
                      value={filterValues[column.name] || ''}
                    />
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      id={`filter${column.name}`}
                      placeholder={`Enter ${column.name} filter`}
                      onChange={(event) => handleFilterChange(event.target.value, column.name)}
                      value={filterValues[column.name] || ''}
                    />
                  )}
                </div>
              )) : "Columns not found"}
              </div>
            </form>
          </div>
          <div className="modal-footer px-2">
              <button type="button" className="btn btn-warning" onClick={clearFilter}>
                  <i className="bi bi-arrow-clockwise mx-2"></i> Clear Filter
              </button>
              <button type="button" className="btn btn-success" onClick={() => applyFilters(filterValues)}>
                  <i className="bi bi-search mx-2"></i> Search
              </button>
          </div>
      </div>
    </Modal>
  )
}

export default ModalFilter
