import { useSelector } from "react-redux"

export default function Dashboard() {

  const { isAuthenticated } = useSelector((state) => state.user)

  const userInfo = JSON.parse(localStorage.getItem('user'))

  return (
    <div className="row">
        <div className="col-xl-6">
            <div className="card-body px-25">
                {isAuthenticated && 
                  <h1 className="banner-feature__heading color-dark fw-bold mb-4">
                    Halo <span className="">{userInfo.name}</span> <i className="text-warning bi bi-emoji-wink"></i> !!!
                  </h1>
                }
                
                <h3 className="banner-feature__heading color-dark fw-bold mb-4">
                  Selamat Datang di Aplikasi H2H Bank Monitoring
                </h3>
                <p className="banner-feature__para color-dark">
                Aplikasi H2H Bank Monitoring adalah sebuah platform
                yang memungkinkan untuk memantau transaksi bank
                secara real-time antara bank satu dengan bank
                lainnya. 
                </p>
            </div>
            </div>
            <div className="col-xl-6">
            <div className="banner-feature__shape">
                <img src="/img/danial.png" alt="img" />
            </div>
        </div>
    </div>
  )
}
