/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import{ Fragment, memo, useCallback, useState } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import { useGetHeaderDataQuery, useGetTableDataQuery } from '../../app/services/api';
import { DNA } from 'react-loader-spinner';
import * as CurrencyFormat from 'react-currency-format';
import ModalFilter from '../modals/ModalFilter';
import MpcLoader from '../MpcLoader'
import { useParams } from 'react-router-dom';
import moment from 'moment';

const ReactDataGridTable =  memo(function ReactDataGridTable(props) {
  const { doubleClickEvent, selectData, lockInject, showFilter, closeModalFilter, selected, filterValue } = props
  const tableName = location.pathname.replace(/\//g, '');

  const { bankId } = useParams()

  // let inject = false;
  const [offset, setOffset] = useState(0);
  const [limit] = useState(100);
  const [search] = useState('');
  const [sort] = useState('');
  const [order] = useState('');
  const [nidKey] = useState('');
  const [nidValue] = useState('');

  const { data: header, isLoading: loadHeader, isFetching: fetchHeader, error: errHeader, isSuccess: columns  } = useGetHeaderDataQuery({
    tablename: `${tableName}`
  });

  const { data: dataBO, isSuccess, isLoading, isFetching, error, isError, refetch } = useGetTableDataQuery({
    tablename: `${tableName}`, 
    skip: offset, 
    limit: limit, 
    search: search,  
    sort: sort, 
    order: order, 
    filter: filterValue ? (Object.keys(filterValue).length < 5 ? '' : filterValue) : '', 
    nidkey: nidKey, 
    nidvalue: nidValue
  });
  
  const filterValueChange = (value) => {
    let keys = Object.keys(value).toString();
    let values = Object.values(value);
    console.log('ky', keys)
    if (keys === "ReceiveTime") {
      const startDate = values.map(date => moment(date[0], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD'));
      const endDate = values.map(date => moment(date[1], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD'));
      return {ReceiveTime: startDate.toString() + ' to ' + endDate.toString()};
    }
    return value
  }

  const applyFilters = (value) => { 
    localStorage.removeItem(tableName);
    const test = filterValueChange(value)
    console.log('value', test)
    if(value) {
      localStorage.setItem(tableName, JSON.stringify(filterValueChange(value))); 
      closeModalFilter();
    }
  };

  const getTable = async ({ data, count }) => {

    const btnRefetch = document.querySelector('.inovua-react-pagination-toolbar__icon--named--REFRESH');
    btnRefetch.addEventListener('click', function() {
      refetch()
    })
    const response = await Promise.resolve({ data, count })
    if(response) {
      return response;
    }
    
    if(tableName === `deposit${bankId}`) {
      localStorage.setItem(tableName, JSON.stringify({
        TransactionCode: 'CREDIT',
        StatusBO: 'SKIPPED'
      }))
    }
  }

  const dataSource = useCallback(() => {
    const data = getTable({
      data: dataBO.rows, count: parseInt(dataBO.total)
    });
    return data;
    
  }, [dataBO]);

  //#region table formatter
  const tableFormatter = (columns) => {
    return columns.map((column) => {
      const colName = column.name.toLowerCase();
      switch(colName){
        case 'statement_id':
          return { ...column, render: lockInject }
        case 'statusbo':
          return { ...column, render: statusBOFormatter }
        case 'receivetime':
        case 'transactiondate':
        case 'sendtime':
          return { ...column, render: dateFormatter }
        case 'transactioncode':
          return { ...column, render: transactionCodeFormatter }
        case 'amount':
        case 'openbalance':
        case 'closebalance':
          return { ...column, render: currencyFormatter }
        default:
          return { ...column, render: defaultFormatter};
      }
    })
  }

  const statusBOFormatter = ({ value }) => {
    if(value === 'SUCCESS') {
      return <div className="bg-success rounded text-center text-white mx-4">{value}</div>
    } else if (value === 'SKIPPED') {
      return <div className="bg-danger rounded text-center text-white mx-4">{value}</div>
    } else {
      return <div>{value}</div>
    }
  }

  const currencyFormatter = ({ value }) => {
    if(value) {
      return <div className="text-end">
          <CurrencyFormat displayType={'text'} value={value} thousandSeparator={true}/>
      </div>
    } else {
      return value
    }
  }

  const dateFormatter = ({ value }) => {
    if(value) {
      return <div className="text-center">
          {moment(value).format("DD MMM YYYY")}
      </div>
    } else {
      return value
    }
  }

  const transactionCodeFormatter = ({ value }) => {
    if(value === 'DEBIT') {
      return <div className="bg-info rounded text-center text-white mx-4">{value}</div>
    } else if (value === 'CREDIT') {
      return <div className="bg-warning rounded text-center text-white mx-4">{value}</div>
    } else {
      return <div>{value}</div>
    }
  }

  const defaultFormatter = ({ value }) => {
    if(value) {
      return <div>{value}</div>
    }

    return "-"
  }
  
  //#endregion  

  let table;

  const renderLoadMask = () => {
    return <div className="container-fluid">
      <div className="row justify-content-center align-items-center InovuaReactDataGrid__load-mask">
        <div className="col-12 text-center spin">
          <DNA visible={true} height="80" width="80" wrapperClass="dna-wrapper"/> <br />
          <span className="fs-4">Please wait...</span>
        </div>
      </div>
    </div>
  }

  if(isLoading) {
    table = <Fragment>
      <ReactDataGrid 
      idProperty='Statement_ID'
      columns={header ? header : []}
      renderLoadMask={renderLoadMask}
      pagination
      dataSource={[]}
      loading={true}
    />
    </Fragment>
  } else if(isFetching) {
    table = <Fragment> 
      <ReactDataGrid 
      idProperty='Statement_ID'
      columns={header ? header : []}
      renderLoadMask={renderLoadMask}
      pagination
      dataSource={[]}
      loading={true}
    />
    </Fragment>
  } else if(isSuccess) {
    table = <Fragment>
      <ReactDataGrid
      idProperty='Statement_ID'
      columns={header ? tableFormatter(header) : []}
      pagination
      skip={offset}
      selected={selected}
      reorderColumns={false}
      showZebraRows={true}
      limit={limit}
      onSkipChange={setOffset} 
      dataSource={dataSource ? dataSource: []}
      onRowDoubleClick={doubleClickEvent}
      onRowClick={selectData} 
    />
      <ModalFilter show={showFilter} closeModalFilter={closeModalFilter} columns={header} applyFilters={applyFilters} />
    </Fragment>

  } else if(isError) {
    table = (
      <div className="row alert alert-danger d-flex align-items-center">
        <div className="col-12 text-center"> 
            <i className="text-danger fs-1 fw-bold bi bi-exclamation-diamond"></i>
        </div>
        <div className="col-12 text-center">
            <h5 className="text-danger fs-4 fw-bold">{error.data} Not Found</h5>
            <p className="text">Please check yout server connection !!! </p>
        </div>
      </div>
    )
  }
  
  return loadHeader ? <MpcLoader/> : fetchHeader ?  <MpcLoader/> : columns ? table : errHeader
})

export default ReactDataGridTable;