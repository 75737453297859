/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import { memo, useEffect, useState } from "react";
import ReactDataGridTable from "../../components/tables/ReactDataGridTable";
import ModalDetail from "../../components/modals/ModalDetail";
import { useInjectDataQuery } from "../../app/services/api";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const Deposit = memo(function Deposit() {
  let defaultValue = {
    TransactionCode: 'CREDIT',
    StatusBO: 'SKIPPED'
  };

  const {bankId} = useParams();

  const [nidKey, setNidKey] = useState('')
  const [paramid, setParamid] = useState('')
  const [paramInject, setParamInject] = useState(null)
  const [showFilter, setShowFilter] = useState(false);

  const location = useLocation()

  const tableName = location.pathname.replace(/\//g, '');
  const filterValue = tableName ? localStorage.getItem(tableName) : [];

  useEffect(() => {
    localStorage.setItem(tableName, JSON.stringify(defaultValue));
  }, [])

  const [showModal, setShowModal] = useState(false)

  const getDetailData = () => {
    setShowModal(true)
    setParamid({ tablename: `${tableName}`, column: 'Statement_ID', paramid: nidKey })
  }

  const selectData = (event) => {
    const nid = event.data.Statement_ID;
    if(nid) {
      setNidKey(nid)
    }
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const closeModalFilter = () => {
    setShowFilter(false)
  }

  const openModalFilter = () => {
    setShowFilter(true)
  }

  const { data: injectResult, isSuccess: successInject, isFetching: loadingInject, isError, error: errorInject } = useInjectDataQuery(paramInject);

  let contentResult;

  if(loadingInject) {
    contentResult = {
      header: (
        <div className="d-flex align-items-center text-white fw-bold">
          <Spinner animation="border" variant="white"/>
          <span className="ms-3">Waiting from progres...</span>
      </div>
      ),
      hideProgressBar: true,
      autoClose: false,
      closeButton: false,
      background: "bg-purple bg-gradient",
    }
  } else if (successInject) {
    if(injectResult.ResponseWS === 0) {
      contentResult = {
        header: (
          <div className="d-flex align-items-center text-white fw-bold">
            <i className="bi bi-check2-circle text-white fs-3"></i>
            <span className="ms-3">Resend successfully</span>
        </div>
        ),
        hideProgressBar: false,
        autoClose: 2000,
        closeButton: false,
        background: "bg-success bg-gradient",
      }
    } else {
      contentResult = {
        header: (
          <div className="d-flex align-items-center text-white fw-bold">
            <i className="bi bi-x-circle text-white cursor-pointer fs-3" title="Click to detail info"></i>
            <span className="ms-3">Resend Failed {injectResult}</span>
        </div>
        ),
        hideProgressBar: true,
        autoClose: false,
        closeButton: true,
        background: "bg-danger bg-gradient",
      }
    }
  } else if(isError) {
    contentResult = {
      header: (
        <div className="d-flex align-items-center text-white fw-bold">
          <i className="bi bi-x-circle text-white cursor-pointer fs-3" title="Click to detail info"></i>
          <span className="ms-3">Resend Failed {errorInject.data}</span>
      </div>
      ),
      hideProgressBar: true,
      autoClose: false,
      closeButton: true,
      background: "bg-danger bg-gradient",
    }
  } 

  const injectSendToBO = async () => {
    await senfToBo(nidKey)
  }

  const senfToBo = (value) => {
    Swal.fire({
      title: "Are you sure?",
      html: `You won't be able to resend this Client <p class="text-danger pt-3 fw-bold">${value}</p>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, resend it!",
      backdrop: "rgba(0, 0, 0, 0.91)"
    }).then((result) => {
      if (result.isConfirmed) {
       setParamInject({ bank: bankId, paramid: value ? value : 0 });
       toast(contentResult.header, {
        position:'top-right',
        autoClose: contentResult.autoClose,
        hideProgressBar: contentResult.hideProgressBar,
        pauseOnHover: false,
        draggable: false,
        closeButton: contentResult.closeButton,
        closeOnClick: false,
        className: contentResult.background,
        progress: undefined,
        theme: "colored",
      });
      }
  });
  }
  
  const lockInject = ({ data, value }) => {
    if(data.StatusBO === 'SKIPPED' && data.TransactionCode === 'CREDIT') {
      return <div>{value} <button onClick={injectSendToBO} className='btn btn-rounded'><i className='bi bi-send pe-2 fs-5'/></button></div>
    } else {
      return value
    }
  }

  return (
    <Layout title={`Notifikasi Mutasi Rekening | ${bankId.toUpperCase()}`}>
      <div className="main-account">
        <div className="col-12 header-account">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-12">
            <nav aria-label="Page navigation example">
              <ul className="pagination pagination-lg">
                <li className="page-item" title="Filter">
                  <button onClick={openModalFilter} className="btn btn-primary page-link bi bi-search"></button>
                </li>
                {
                  filterValue ? Array(JSON.parse(filterValue)).map((value) => (
                    <li key={value} className="page-item" title="Filter"> 
                      <span className="ms-3">Filtered by:</span>
                      <p className="ms-3">
                      { Object.keys(value).length <= 0 ? (
                        <span className="fw-bold fs-5 me-2">None</span>
                      ) : Object.values(Object.entries(value)).map((item) => (
                        <span className="fw-bold" key={item}><span className="fs-5 me-2"> |</span>{item[0]}: {item[1]}</span>
                      ))}</p>
                      
                    </li>  
                  )) : 'None'
                  
                }
                
              </ul>
            </nav>
            </div>
          </div>
        </div>
        <div className="col-12">
          {paramid && <ModalDetail show={showModal} bank={bankId.toUpperCase()} closeModal={closeModal} title="Deposit Detail" paramid={paramid} />}
          <ReactDataGridTable doubleClickEvent={getDetailData} lockInject={lockInject} selectData={selectData} showFilter={showFilter} closeModalFilter={closeModalFilter} selected={nidKey} filterValue={filterValue}/>
        </div>
      </div>
    </Layout>
  )
})

export default Deposit;