import { Link } from "react-router-dom";
import moment from 'moment';

export default function NotFound() {

  const dateString = "08252017 143801";
  
  // Mengonversi string ke objek moment
  const momentObject = moment(dateString, 'MMDDYYYY HHmmss').format("YYYY-MM-DDTHH:mm:ss");
  
  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-12 text-center">
          <h1 className="fw-bold">Oooops... Page Not Found</h1>
          <img src="/img/NotFound.svg" alt="" className="w-50"/>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 text-center mt-5">
          <span className="text-404">404</span>
        </div>
        <div className="col-12 text-center">
          <Link className="btn btn-info bi bi-" to="/" >Back to Home</Link>
          <p>{momentObject}</p>
        </div>
      </div>
    </div>
  )
}
