
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import Footer from './components/Footer';

export default function App() {
  const [show, setShow] = useState(true);

  const toggleSideBar = () => { 
    setShow(!show);
  };

  return (
    <main className={show ? 'space-toggle' : null}>

        <Header toggleSideBar={toggleSideBar}/>

        <Sidebar show={show}/>

        <div className="col-xxl-12">
            <div className="card banner-feature--18 new d-flex bg-white">
                <div className="container-fluid">
                  <Outlet/>
                </div>
            </div>
        </div>

        <Footer/>
        
      </main>
  )
}
